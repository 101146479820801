<template>
    <modal ref="modalNuevaComision" :titulo="`${ comisionAccion == 1 ? 'Nueva' : 'Editar'}  comisión`" icon="money" @guardar="crear_comision">
        <ValidationObserver ref="validacion">
            <div class="row mx-0 justify-center">
                <div class="col pl-4 d-middle">
                    Asignar Porcentaje de comisión
                </div>
                <div class="col-3 px-2">
                    <ValidationProvider v-slot="{errors}" rules="required|numeric|max:2" name="comisión">
                        <el-input v-model="model.comision" class="w-100 prepend-edit">
                            <div slot="prepend">
                                %
                            </div>
                        </el-input>
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mt-4 mx-0 justify-center">
                <div class="col">
                    <label class="pl-2 text-muted2"> Tiempo de vigencia de comisión </label>
                    <ValidationProvider v-slot="{errors}" rules="required" name="vigencia">
                        <el-date-picker
                        v-model="tiempo_vigencia"
                        format="dd-MM-yyyy"
                        value-format="yyyy-MM-dd"
                        type="daterange"
                        unlink-panels
                        popper-class="date-picker-edit-popper"
                        start-placeholder="Fecha inicio"
                        end-placeholder="Fecha fin"
                        />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <!-- <div class="col-3" /> -->
                <div class="col-12 pl-4  mt-4 my-2">
                    Escribe el mensaje que desea enviarle al tendero
                </div>
                <div class="col mb-4">
                    <ValidationProvider v-slot="{errors}" rules="required|max:500" name="observaciones">
                        <el-input v-model="model.observaciones" type="textarea" class="w-100" :rows="5" maxlength="500" show-word-limit />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import moment from 'moment'
import Comisiones from "../../../services/comisiones";

export default {
    props: {
        comisionAccion: {
            type: Number,
            default: 1
        }
    },
    data(){
        return{
            porcentaje: '',
            tiempo_vigencia: [],
            mensaje_tendero: '',
            model:{
                id: null,
                comision: null,
                observaciones: null,
                fecha_inicio: null,
                fecha_fin: null,
                id_user: null,
            }
        }
    },
    methods: {
        toggle(params){
            this.model = params
            this.tiempo_vigencia = [
                moment(params.fecha_inicio).toDate(),
                moment(params.fecha_fin).toDate(),
            ]
            this.$refs.modalNuevaComision.toggle();
        },
        async crear_comision(){
            try {

                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                this.model.fecha_inicio = moment(this.tiempo_vigencia[0]).format('Y-MM-DD')
                this.model.fecha_fin = moment(this.tiempo_vigencia[1]).format('Y-MM-DD')

                if (this.model.id == null ){
                    const {data} = await Comisiones.crear_comision(this.model)
                    this.notificacion('Mensaje', data.error ?? data.mensaje, data.error ? 'warning' : 'success')
                    if(!data.error){
                        this.$emit('listar')
                        this.$refs.modalNuevaComision.toggle()
                    }
                } else {
                    const {data} = await Comisiones.editar_comision(this.model)
                    this.notificacion('Mensaje', data.error ?? data.mensaje, data.error ? 'warning' : 'success')
                    if(!data.error){
                        this.$emit('listar')
                        this.$refs.modalNuevaComision.toggle()
                    }
                }

            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

<style>

</style>
